exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/About.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/Category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-home-page-jsx": () => import("./../../../src/templates/HomePage.jsx" /* webpackChunkName: "component---src-templates-home-page-jsx" */),
  "component---src-templates-lectures-jsx": () => import("./../../../src/templates/Lectures.jsx" /* webpackChunkName: "component---src-templates-lectures-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/Project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-projects-jsx": () => import("./../../../src/templates/Projects.jsx" /* webpackChunkName: "component---src-templates-projects-jsx" */),
  "component---src-templates-simple-page-jsx": () => import("./../../../src/templates/SimplePage.jsx" /* webpackChunkName: "component---src-templates-simple-page-jsx" */)
}

