import React, { createContext, useContext, useMemo, useState } from "react";

const ThemeContext = createContext({
  settings: {},
  page: {},
  currentImageIndex: null,
  showImageModal: false,
  allGatsbyImageData: [],
  setCurrentImageIndex: () => { },
  handleCloseImageModal: () => { },
  handleShowImageModal: () => { },
});

const ThemeProvider = ({ children, pageData }) => {
  const { settings, page } = pageData;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showImageModal, setShowImageModal] = useState(false);
  const handleCloseImageModal = () => setShowImageModal(false);
  const handleShowImageModal = () => setShowImageModal(true);
  const allGatsbyImageData = [];

  const value = useMemo(
    () => ({
      settings,
      page,
      currentImageIndex,
      showImageModal,
      allGatsbyImageData,
      setCurrentImageIndex,
      handleCloseImageModal,
      handleShowImageModal,
    }),
    [settings, page, currentImageIndex, showImageModal, allGatsbyImageData]
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error("useTheme must be used within ThemeProvider");
  }

  return context;
};

export { ThemeContext, ThemeProvider, useTheme };
